import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import Logo from '../../Logo';

export const Login = ({ apiUrl }: { apiUrl: string }): JSX.Element => {
  const { agentId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get('status');
  const [googleLink, setGoogleLink] = useState<string>('');
  const [azureLink, setAzureLink] = useState<string>('');

  useEffect(() => {
    if (status != null) {
      return;
    }
    const fetchGoogleLink = async () => {
      try {
        const url = `${apiUrl}/api/auth/google/${agentId}`;
        const response = await fetch(url);
        const jsonData = await response.json();
        setGoogleLink(jsonData.url);
      } catch (err) {
        console.error('An error occurred while fetching data:', err);
      }
    };
    const fetchAzureLink = async () => {
      try {
        const url = `${apiUrl}/api/auth/azure/${agentId}`;
        const response = await fetch(url);
        const jsonData = await response.json();
        setAzureLink(jsonData.url);
      } catch (err) {
        console.error('An error occurred while fetching data:', err);
      }
    };
    fetchGoogleLink().then().catch(console.log);
    fetchAzureLink().then().catch(console.log);
  }, [status]);
  if (status != null) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: '20px',
        }}
      >
        <Logo />
        <div style={{ marginTop: '20px' }}>
          {status === 'success' ? (
            <h1>Successfully logged in!</h1>
          ) : (
            <h1>Failed to log in</h1>
          )}
        </div>
      </div>
    );
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh',
        paddingTop: '100px',
      }}
    >
      <div style={{ position: 'absolute', top: '20px' }}>
        <Logo />
      </div>
      <div
        style={{
          width: '100%',
        }}
      >
        <div
          style={{
            fontSize: '24px',
            lineHeight: '32px',
            textAlign: 'center',
            fontWeight: '300',
          }}
        >
          Enable calendar access by signing into HouseWhisper with your calendar
          provider
        </div>
        <div
          style={{
            marginTop: '50px',
            width: '100%',
          }}
        >
          <img
            alt="Google Sign In"
            onClick={() => (window.location.href = googleLink)}
            src="/google-sign-in.svg"
            style={{
              cursor: 'pointer',
              width: '100%',
              maxWidth: '250px',
            }}
          />
        </div>
        <div
          style={{
            marginTop: '20px',
            width: '100%',
          }}
        >
          <img
            alt="Azure Sign In"
            onClick={() => (window.location.href = azureLink)}
            src="/ms-sign-in.svg"
            style={{
              cursor: 'pointer',
              width: '100%',
              maxWidth: '250px',
            }}
          />
        </div>
      </div>
    </div>
  );
};
