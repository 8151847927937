import { Col, Divider, Input, List, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Agent } from '../../types';

import { Conversation } from './Conversation';

const featureLevelOrder = {
  live: 1,
  shadow: 2,
  disabled: 3,
};

const sortAgents = (agents: Agent[]) => {
  return agents.sort((a, b) => {
    const featureLevelComparison =
      featureLevelOrder[a.feature_level] - featureLevelOrder[b.feature_level];
    if (featureLevelComparison !== 0) {
      return featureLevelComparison;
    }
    const firstNameComparison = a.first_name.localeCompare(b.first_name);
    if (firstNameComparison !== 0) {
      return firstNameComparison;
    }
    return a.last_name.localeCompare(b.last_name);
  });
};
export const AgentSmsPage = ({ apiUrl }: { apiUrl: string }): JSX.Element => {
  const { clientId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [agents, setAgents] = useState<Agent[]>([]);
  const [nameSearch, setNameSearch] = useState<string>('');
  const [filteredAgents, setFilteredAgents] = useState<Agent[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [activeAgent, setActiveAgent] = useState<Agent | undefined>(undefined);
  const agentId = searchParams.get('agentId');
  const [friendlyName, setFriendlyName] = useState<string>('');
  const [referralUrl, setReferralUrl] = useState<string>('');

  const fetchAgents = async () => {
    try {
      setLoading(true);
      const url = `${apiUrl}/api/configs/${clientId}`;
      const response = await fetch(url);
      const jsonData = await response.json();
      const agents: Agent[] = jsonData.agents;
      if (agentId) {
        setActiveAgent(agents.find(agent => agent.id === agentId));
      }
      const friendlyName: string = jsonData.friendly_name;
      if (friendlyName) {
        setFriendlyName(friendlyName);
      }
      const referralUrl: string = jsonData.referral_url;
      if (referralUrl) {
        setReferralUrl(referralUrl);
      }
      const filteredAgents = agents.filter(agent => agent.first_name != null);
      const sortedAgents = filteredAgents.sort((a, b) =>
        a.first_name.localeCompare(b.first_name),
      );
      setAgents(sortedAgents);
    } catch (err) {
      console.error('An error occurred while fetching the agent:', err);
      setAgents([]);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchAgents().then().catch(console.log);
  }, []);

  useEffect(() => {
    if (nameSearch === '') {
      setFilteredAgents(agents);
    } else {
      setFilteredAgents(
        agents.filter(agent => {
          return (
            agent.first_name.toLowerCase().includes(nameSearch.toLowerCase()) ||
            agent.last_name.toLowerCase().includes(nameSearch.toLowerCase())
          );
        }),
      );
    }
  });

  const sortedAgents = sortAgents(filteredAgents);
  return (
    <div>
      <h1>Agent SMS</h1>
      <p>
        <a href={referralUrl} rel="noopener noreferrer" target="_blank">
          {friendlyName}
        </a>
      </p>
      <Row gutter={16}>
        <Col sm={{ span: 7, order: 0 }} xs={{ span: 24, order: 1 }}>
          <Input
            onChange={e => setNameSearch(e.target.value)}
            placeholder="Search by name"
            style={{ flex: 0.8 }}
            value={nameSearch}
          />
          <List
            dataSource={sortedAgents}
            loading={loading}
            renderItem={item => {
              let extra_style = {};
              if (item.id == activeAgent?.id) {
                extra_style = { backgroundColor: 'rgba(128, 128, 128, 0.2)' };
              }
              return (
                <List.Item
                  onClick={() => {
                    setActiveAgent(item);
                    setSearchParams({ agentId: item.id });
                  }}
                  style={{
                    textAlign: 'left',
                    ...extra_style,
                  }}
                >
                  {item.first_name} {item.last_name}{' '}
                  {item.is_isa && <span>(ISA)</span>}
                  {item.feature_level === 'shadow' && (
                    <span style={{ fontWeight: 'lighter' }}>
                      (shadow mode, do not send sms)
                    </span>
                  )}
                  {item.feature_level === 'disabled' && (
                    <span style={{ fontWeight: 'lighter' }}>
                      (disabled, do not send sms)
                    </span>
                  )}
                </List.Item>
              );
            }}
            style={{ overflow: 'auto', height: '80vh' }}
          />
        </Col>
        <Col sm={1} xs={0}>
          <Divider style={{ height: '95%' }} type="vertical" />
        </Col>
        <Col sm={{ span: 16, order: 2 }} xs={{ span: 24, order: 0 }}>
          {activeAgent && <Conversation agent={activeAgent} apiUrl={apiUrl} />}
        </Col>
      </Row>
    </div>
  );
};
